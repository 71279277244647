import React, { useRef, useEffect } from 'react';

type Props = {
  children: React.ReactNode;
  showLoadingTrigger?: boolean;
  onTrigger: () => void;
};

const InfinteScroll = ({ showLoadingTrigger, children, onTrigger }: Props) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const ref = observerTarget.current;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onTrigger();
        }
      },
      { threshold: 0, rootMargin: '0px 0px 400px 0px' },
    );

    if (ref) {
      observer.observe(ref);
    }

    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
  }, [observerTarget, onTrigger, showLoadingTrigger]);

  return (
    <>
      {children}
      {showLoadingTrigger && <div ref={observerTarget} />}
    </>
  );
};
export default InfinteScroll;
